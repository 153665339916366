import { Check, Trash } from "@phosphor-icons/react";
import {
  DeleteButtonProps,
  DeleteWithConfirmButton,
  SaveButton,
  SaveButtonProps,
  Toolbar,
  ToolbarProps,
  useGetResourceLabel,
  useResourceContext,
} from "react-admin";
import { useCurrentPlatform } from "~/context";

type Props = ToolbarProps & {
  showSave?: boolean;
  showDelete?: boolean;
  deleteButtonProps?: DeleteButtonProps;
  saveButtonProps?: SaveButtonProps;
};

export const StyledToolBar = ({
  showSave = true,
  showDelete = true,
  deleteButtonProps,
  saveButtonProps,
  ...props
}: Props) => {
  const resource = useResourceContext(props);
  const getResourceLabel = useGetResourceLabel();
  const resource_name = getResourceLabel(resource);
  const { getTerm } = useCurrentPlatform();
  const label = getTerm(resource_name, { capitalize: true });

  return (
    <Toolbar {...props}>
      {showSave && (
        <SaveButton
          type="button"
          endIcon={<Check />}
          // @ts-expect-error
          icon={null}
          {...saveButtonProps}
        />
      )}
      {showDelete && (
        <DeleteWithConfirmButton
          label={`Delete ${label}`}
          endIcon={<Trash />}
          startIcon={null}
          {...deleteButtonProps}
          confirmTitle={`Delete ${label}`}
          confirmContent={`Are you sure you want to permanently delete this ${getTerm(
            resource
          )}?`}
        />
      )}
    </Toolbar>
  );
};
