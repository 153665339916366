import { Box, Tooltip, Typography } from "@mui/material";
import {
  AppleLogo,
  Books,
  ChartLineUp,
  FacebookLogo,
  FlagCheckered,
  Gear,
  Handshake,
  Newspaper,
  User,
} from "@phosphor-icons/react";
import { Menu } from "@react-admin/ra-rbac";
import { filter, merge } from "lodash";
import { useEffect } from "react";
import {
  useResourceDefinitionContext,
  useResourceDefinitions,
  useStore,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { useCurrentPlatform } from "~/context";
import { Logo } from "~/images/Logo";
import { LayoutClasses } from "../layoutClasses";
import PlatformMenu from "../PlatformMenu";
import { MenuSection } from "./MenuSection";

// this has been extended to account for user roles, and platform_user roles
const MENU_GROUPS = [
  {
    key: "places",
    name: "Places",
    primary_resource: "regions",
    defaultExpanded: true,
  },
  {
    key: "events",
    name: "Events",
    primary_resource: "events",
    defaultExpanded: true,
    requiredExtension: "Events",
  },
  {
    key: "content",
    name: "Content",
    requiredRole: ["admin", "super_admin", "viewer"],
    primary_resource: "contents",
    defaultExpanded: true,
  },
  {
    key: "collaborate",
    name: "Collaborate",
    primary_resource: "platforms",
    defaultExpanded: true,
    requiredRole: ["admin", "super_admin"],
  },
  {
    key: "communicate",
    name: "Communicate",
    primary_resource: "newsletters",
    defaultExpanded: true,
    requiredRole: ["admin", "super_admin"],
  },
  {
    key: "contesting",
    name: "Contesting",
    primary_resource: "challenges",
    defaultExpanded: false,
    requiredRole: ["admin", "super_admin"],
    requiredExtension: "Contesting",
  },
  {
    key: "reports",
    name: "Reports",
    primary_resource: "analytics",
    defaultExpanded: false,
    requiredRole: ["admin", "super_admin", "viewer"],
  },
  {
    key: "admin",
    name: "Admin",
    defaultExpanded: false,
    requiredRole: ["admin", "super_admin"],
  },
];

const useSetResourceLabels = () => {
  const { definitions: resources, register } = useResourceDefinitionContext();
  const { currentPlatform, getTerm } = useCurrentPlatform();
  useEffect(() => {
    if (currentPlatform?.id) {
      Object.entries(resources).forEach(([resource, props]) => {
        const { options } = props;
        const label = getTerm(resource, { plural: true, capitalize: true });
        if (label !== options?.label) {
          register(merge({}, props, { options: { label } }));
        }
      });
    }
  }, [resources, getTerm, register, currentPlatform?.id]);
};

export const Sidebar = () => {
  const resources = useResourceDefinitions();
  const location = useLocation();
  const [expanded, setExpanded] = useStore(`menu.accordion`);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  useEffect(() => {
    // Determine which accordion group should be expanded based on the current path
    MENU_GROUPS.forEach((mg) => {
      const resources = filter(
        menu_resources,
        (v) => v.options?.menu_group === mg.key
      );

      const shouldExpand = resources.some(
        (resource) => location.pathname.split("/")[1] == resource.name
      );
      if (shouldExpand) {
        setExpanded(mg.key);
      }
    });
  }, [location.pathname]);

  //I'm not convinced that setting resource labels in the admin is a good thing...
  //useSetResourceLabels();
  const { currentPlatform } = useCurrentPlatform();
  const menu_resources = {
    ...resources,
    platform: {
      icon: <Gear />,
      name: "platforms",
      type: "edit",
      options: {
        menu_group: "admin",
        label: "Platform Settings",
        resource_id: currentPlatform?.id,
      },
    },

    content_report: {
      icon: <Books />,
      name: "reports",
      to: "/reports/content",
      type: "content",
      options: {
        menu_group: "reports",
        label: "Content",
      },
    },
    web_report: {
      icon: <ChartLineUp />,
      name: "reports",
      to: "/reports/web",
      type: "web",
      options: {
        menu_group: "reports",
        label: "Web Traffic",
      },
    },
    app_report: {
      icon: <AppleLogo />,
      name: "reports",
      to: "/reports/app",
      type: "app",
      options: {
        menu_group: "reports",
        label: "App Activity",
        requiredExtension: "App",
      },
    },
    user_feedback: {
      icon: <User />,
      name: "reports",
      to: "/reports/user_feedback",
      type: "user_feedback",
      options: {
        menu_group: "reports",
        label: "User Feedback",
      },
    },
    collaboration_report: {
      icon: <Handshake />,
      name: "reports",
      to: "/reports/collaboration",
      type: "collaboration",
      options: {
        menu_group: "reports",
        label: "Collaboration",
      },
    },
    contest_report: {
      icon: <FlagCheckered />,
      name: "reports",
      to: "/reports/contest",
      type: "contest",
      options: {
        menu_group: "reports",
        label: "Contests",
      },
    },
    email_report: {
      icon: <Newspaper />,
      name: "reports",
      to: "/reports/communication",
      type: "communication",
      options: {
        menu_group: "reports",
        label: "Communications",
      },
    },
    socials_report: {
      icon: <FacebookLogo />,
      name: "reports",
      to: "/reports/socials",
      type: "socials",
      options: {
        menu_group: "reports",
        label: "Socials",
      },
    },
    // videos: {
    //   icon: <VideoCamera />,
    //   name: "Videos",
    //   options: { menu_group: "content" },
    // },
    // posts: {
    //   icon: <Scroll />,
    //   name: "Posts",
    //   options: { menu_group: "content" },
    // },
    // notifications: {
    //   icon: <BellRinging />,
    //   name: "Notifications",
    //   options: { menu_group: "communicate" },
    // },
    // audio: {
    //   icon: <SpeakerHigh />,
    //   name: "Sound Clips",
    //   options: { menu_group: "content" },
    // },
  };
  return (
    <div className={LayoutClasses.sidebar}>
      <PlatformMenu />

      <Tooltip
        title={"Home Page"}
        className={LayoutClasses.tooltip}
        enterDelay={500}
      >
        {/* Box is needed because DashboardItem doesn't forwardRef which is needed for tooltip*/}
        <Box>
          <Menu.DashboardItem
            variant="main"
            primaryText={
              <Typography
                color="white"
                variant="body2"
                sx={{ fontWeight: 500 }}
                whiteSpace="normal"
              >
                Dashboard
              </Typography>
            }
            // leftIcon={<House />}
            sx={{
              mt: "auto",
              fontSize: 24,
              paddingLeft: "1em",
            }}
            className={LayoutClasses.menuItem}
          />
        </Box>
      </Tooltip>

      <Menu className={LayoutClasses.menu}>
        {MENU_GROUPS.map((mg) => (
          <MenuSection
            key={mg.key}
            menu_group={mg}
            menu_resources={filter(
              menu_resources,
              (v) => v.options?.menu_group === mg.key
            )}
            expanded={expanded === mg.key}
            onChange={handleAccordionChange(mg.key)}
          />
        ))}
        {/* <MenuItem resource={menu_resources["help_topics"]} key="help_topics" />; */}
      </Menu>
      <a
        href="https://tourismo.co"
        target="_blank"
        style={{ color: "white", textDecoration: "none" }}
      >
        <Typography
          sx={{
            display: "flex", // Use flexbox layout
            alignItems: "center", // Vertically center items
            justifyContent: "center", // Horizontally center items
            color: "white",
            textAlign: "center",
            padding: "5px",
            textDecoration: "none",
          }}
        >
          powered by
          <Logo
            color={"white"}
            sx={{ display: "inline-block", width: "90px", marginTop: "-3px" }}
          />
        </Typography>
      </a>
    </div>
  );
};
