import { Grid } from "@mui/material";
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
} from "react-admin";
import { ImageTabs, Stats, StyledToolBar } from "~/components";
import { GridCard } from "~/components/GridCard";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { RewardList } from "~/resources/rewards";
import { ContentList } from "../contents";
import { TasksList } from "../tasks";
import { TargetSelect } from "./TargetSelect";
import { TaskIntervalInputs } from "./TaskIntervalInputs";

const CHALLENGE_TYPE_DESCRIPTIONS = {
  UniqueTask: "A task that can only be completed once.",
  RepeatTask: "A task that can be completed multiple times.",
  RewardsEarned: "A task where rewards are earned upon completion.",
  SequentialDays: "A task that must be completed on sequential days.",
  SequentialWeekends: "A task that must be completed on sequential weekends.",
  SocialShare: "A task that involves sharing on social media.",
  AnyCheckIn: "A task that can be completed with any check-in.",
};

export const ChallengeEdit = () => {
  const { getTerm } = useCurrentPlatform();
  const challengeCapTerm = getTerm("challenge", { capitalize: true });
  const rewardsCapTerm = getTerm("reward", { plural: true, capitalize: true });
  const challengeTerm = getTerm("challenge");
  return (
    <Edit redirect={false} mutationMode="pessimistic">
      <TabbedForm
        variant="standard"
        toolbar={<StyledToolBar showDelete={false} />}
      >
        <FormTab label="Basic Setup">
          <Grid container spacing={2} width="100%">
            <Grid item xs={8}>
              <TextInput source="name" validate={required()} fullWidth />
              <ItinMarkdownInput
                title="Description"
                height="300px"
                placeholder="Compose an epic.."
                source="description"
                isRequired={false}
              />
            </Grid>
            <Grid item xs={4}>
              <EnumChoiceInput source="status" isRadio={true} />
              <BooleanInput
                source="featured"
                label={`Make this a featured ${challengeTerm}`}
              />
              <AdminUserInput fullWidth />
              <DateTimeInput
                source="start_date"
                fullWidth
                InputLabelProps={null}
              />
              <DateTimeInput
                source="end_date"
                fullWidth
                InputLabelProps={null}
              />
            </Grid>
          </Grid>
          <ContentList />
        </FormTab>
        <FormTab label="Configuration & Tasks">
          <Grid container>
            <GridCard lg={6} title="Primary Target">
              <TargetSelect />
            </GridCard>
            <GridCard lg={6} title="Challenge Mode">
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <EnumChoiceInput
                    source="type"
                    label={`${challengeCapTerm} Type`}
                    fullWidth={true}
                    helperText={(value) => CHALLENGE_TYPE_DESCRIPTIONS[value]}
                  />
                </Grid>
                <Grid item md={6}>
                  <TaskIntervalInputs />
                </Grid>
              </Grid>
            </GridCard>
            <Grid item xs={12} title={"Tasks"}>
              <TasksList />
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={rewardsCapTerm}>
          <RewardList />
        </FormTab>

        <FormTab label="images">
          <ImageTabs />
        </FormTab>
        {/* <FormTab label={`${userCapTerm} Interactions`}>
          <UserInteractionsChallenges />
        </FormTab> */}
        <FormTab label="Reports">
          <ReportFilterProvider>
            <TourismoDateRangePicker />
            <Stats category="report" />
          </ReportFilterProvider>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
