import { Typography, Grid } from "@mui/material";
import { Loading } from "react-admin";
import { useStats } from "~/hooks";
import { SummaryCards } from "./SummaryCards";
import { DEFAULT_STATS_ICONS } from "./stats_constants";
export const Stats = (props) => {
  const { data, isLoading } = useStats(props);
  if (isLoading) return <Loading />;
  if (typeof data == "undefined" || data == null) return <></>;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ marginBottom: "10px" }}>
          {data.title}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="body" sx={{ marginBottom: "20px" }}>
          {data.description}
        </Typography>
      </Grid>
      <SummaryCards cards={data.cards} />
    </Grid>
  );
};
