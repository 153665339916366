import { createContext, useContext, useState } from "react";
import { Edit, FormTab, TabbedForm } from "react-admin";
import { ImageTabs, Stats, StyledToolBar } from "~/components";
import { validateEditorRedirect } from "~/helpers/validateEditorRedirect";
import { LinkedDmos } from "../dmos";
import { LogList } from "../logs/LogList";
import { ItineraryOverview } from "./ItineraryOverview/ItineraryOverview";
import { EditBasicTab, EditContentTab, EditStopsTab } from "./edit";

export const ItineraryEdit = () => {
  const [stopRef, setStopRef] = useState();
  return (
    <StopDrawerContext.Provider value={{ ...stopRef, setStopRef }}>
      <Edit
        mutationMode="pessimistic"
        title=" "
        redirect={false}
        actions={false}
      >
        <ItineraryForm />
      </Edit>
    </StopDrawerContext.Provider>
  );
};

const StopDrawerContext = createContext(undefined);

export const useStopDrawerContext = () => {
  const context = useContext(StopDrawerContext);
  if (context === undefined)
    throw new Error(
      "useStopDrawerContext cannot be used outside a StopDrawerProvider"
    );
  return context;
};

export const ItineraryForm = () => {
  validateEditorRedirect();

  return (
    <TabbedForm redirect={false} variant="standard" toolbar={<StyledToolBar />}>
      <FormTab label="Overview">
        <ItineraryOverview />
      </FormTab>
      <EditBasicTab />
      <EditContentTab />
      <EditStopsTab />
      <FormTab label="DMOs">
        <LinkedDmos />
      </FormTab>
      <FormTab label="Images">
        <ImageTabs />
      </FormTab>
      <FormTab label="Reports">
        {/* <UserInteractionsItinerary /> */}
        <Stats category="report" />
      </FormTab>

      <FormTab label="Activity Log">
        <LogList />
      </FormTab>
    </TabbedForm>
  );
};
