import { Grid, Typography } from "@mui/material";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { LocationFields, StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { EnumChoiceInput } from "~/components/inputs";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { useCurrentPlatform } from "../../context";
import { ParentRegionInput } from "./ParentRegionInput";

export const RegionCreate = () => {
  const { loading, currentPlatform, getTerm } = useCurrentPlatform();
  const regionTerm = getTerm("region");
  if (loading) return <ItinLoading />;
  return (
    <Create>
      <SimpleForm
        variant="standard"
        defaultValues={{
          platform_id: currentPlatform.id,
          status: "active",
        }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          {`New ${getTerm("region", { capitalize: true })} `}
        </Typography>
        <Grid container columnSpacing={3} marginBottom={3}>
          <Grid item xs={12} md={6}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <EnumChoiceInput source="status" fullWidth isRadio={true} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ParentRegionInput source="parent_region_id" />
            <ItinRichTextArea
              title="Description"
              description={`A full description you would like associated with your ${regionTerm}. This will appear on expanded views of your ${regionTerm} for the ${getTerm(
                "user"
              )}.`}
              height="200px"
              placeholder="Compose an epic.."
              source="description"
            />
          </Grid>
          <Grid item sx={12}>
            <LocationFields />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default RegionCreate;
