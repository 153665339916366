import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Show, TabbedShowLayout, useGetIdentity } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";

export const WebReport = () => {
  useDefineAppLocation("reports.web");
  const { currentPlatform, isLoading } = useCurrentPlatform();
  const { identity, isLoading: isLoadingIdentity } = useGetIdentity();
  if (isLoading || isLoadingIdentity) return "";

  return (
    <ReportFilterProvider>
      <TourismoDateRangePicker />
      <Show resource="platforms" id={currentPlatform.id} actions={false}>
        <TabbedShowLayout>
          {identity?.dmo_id && (
            <TabbedShowLayout.Tab label="Regional Web Traffic">
              <Stats category="web" resource="dmos" id={identity?.dmo_id} />
            </TabbedShowLayout.Tab>
          )}
          <TabbedShowLayout.Tab label="Platform Web Traffic">
            <Stats category="web" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Platform Web Sources">
            <Stats category="web_sources" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Platform Web Audience">
            <Stats category="web_audience" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </ReportFilterProvider>
  );
};
