import { LicenseInfo } from "@mui/x-license";
import { Admin } from "@react-admin/ra-enterprise";
import * as Sentry from "@sentry/react";
import { CustomRoutes, Resource } from "react-admin";
import TagManager from "react-gtm-module";
import { QueryClient } from "react-query";
import { BrowserRouter, Route } from "react-router-dom";
import { authProvider, dataProvider, i18nProvider } from "~/lib";
import {
  AreaResource,
  ChallengeResource,
  CommunicationResource,
  ContentTypeResource,
  DmoResource,
  EventResource,
  FormEntryResource,
  FormFieldResource,
  FormResource,
  HelpTopicResource,
  Home,
  IngestionResource,
  ItineraryResource,
  LegResource,
  ListingResource,
  LogResource,
  Login,
  MediaResource,
  NewsletterResource,
  PlatformAreaResource,
  PlatformLevelResource,
  PlatformResource,
  QRCodeResource,
  ReelResource,
  RegionResource,
  RewardResource,
  StopResource,
  TagResource,
  UserResource,
} from "~/resources";
import { lightTheme } from "~/theme";
import { Layout } from "./components/layout";
import {
  AppReport,
  CollaborationReport,
  CommunicationReport,
  ContentReport,
  ContestReport,
  Reports,
  SocialReport,
  UserFeedback,
  WebReport,
} from "./resources/reports";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

LicenseInfo.setLicenseKey(
  "76ad6ef1f7e00c5099c532f5152f2720Tz05NjgxMSxFPTE3NTYwNDIxMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

Sentry.init({
  dsn: "https://b96e3ff8149c5a3409a933f1be2dff08@o489268.ingest.us.sentry.io/4507144066957312",
  environment: process.env.NODE_ENV || "production",
  // integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30 * 1000, //30 seconds
    },
  },
});
const tagManagerArgs = {
  gtmId: "GTM-5V8F4JH",
  dataLayer: {
    js: new Date(),
    config: "GTM-5V8F4JH",
  },
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <BrowserRouter>
      <Admin
        dataProvider={dataProvider}
        layout={Layout}
        authProvider={authProvider}
        lightTheme={lightTheme}
        queryClient={queryClient}
        i18nProvider={i18nProvider}
        loginPage={Login}
        requireAuth
        dashboard={Home}
      >
        <Resource {...ListingResource} />
        <Resource {...ItineraryResource} />
        <Resource {...ReelResource} />
        <Resource {...RegionResource} />
        <Resource {...TagResource} />
        <Resource {...LogResource} />
        <Resource {...NewsletterResource} />
        <Resource {...ChallengeResource} />
        <Resource {...FormResource} />
        <Resource {...FormEntryResource} />
        <Resource {...QRCodeResource} />
        <Resource {...PlatformLevelResource} />
        <Resource {...CommunicationResource} />
        <Resource {...ContentTypeResource} />
        <Resource {...EventResource} />
        <Resource {...MediaResource} />
        <Resource {...UserResource} />
        <Resource {...DmoResource} />
        <Resource {...StopResource} />
        <Resource {...LegResource} />
        <Resource {...IngestionResource} />
        <Resource {...PlatformAreaResource} />
        <Resource {...AreaResource} />
        {/* <Resource {...AnalyticsResource}>
          <Route path="/mobile" element={<MobileAnalytics />} />
          <Route path="/webtraffic" element={<WebAnalytics />} />
          <Route path="/printadvertising" element={<></>} />
          <Route path="/digitaladvertising" element={<></>} />
        </Resource> */}
        <CustomRoutes>
          <Route path="/reports/" element={<Reports />} />
          <Route path="/reports/socials/*" element={<SocialReport />} />
          <Route path="/reports/web/*" element={<WebReport />} />
          <Route path="/reports/app/*" element={<AppReport />} />
          <Route path="/reports/contest/*" element={<ContestReport />} />
          <Route path="/reports/content/*" element={<ContentReport />} />
          <Route
            path="/reports/communication/*"
            element={<CommunicationReport />}
          />
          <Route path="/reports/user_feedback/*" element={<UserFeedback />} />
          <Route
            path="/reports/collaboration"
            element={<CollaborationReport />}
          />
        </CustomRoutes>
        <Resource name="dmos_itineraries" />
        <Resource name="dmos_regions" />
        <Resource name="operating_hours" />
        <Resource name="contents" />
        <Resource name="platform_listings" />
        <Resource name="event_times_locations" />
        <Resource name="event_times" />
        <Resource name="locations" recordRepresentation="name" />
        <Resource name="seasons" />
        <Resource name="socials" />
        <Resource name="taggings" recordRepresentation={(r) => r.name} />
        <Resource name="tasks" />
        <Resource name="platform_users" recordRepresentation={(r) => r.name} />
        <Resource name="platform_user_rewards" />
        <Resource name="rewards" {...RewardResource} />
        <Resource name="platform_user_tasks" />
        <Resource name="user_check_ins" />
        <Resource name="images" />
        <Resource name="hero" />
        <Resource name="carousel_images" />
        <Resource name="platform_extensions" recordRepresentation="type" />
        <Resource name="blobs" />
        <Resource name="content_hooks" recordRepresentation="name" />
        <Resource name="newsletter_users" recordRepresentation="name" />
        <Resource {...FormFieldResource} />
        <Resource {...HelpTopicResource} />,
        <Resource {...PlatformResource} />
      </Admin>
    </BrowserRouter>
  );
};

export default App;
