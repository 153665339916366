import { Box, Stack, Typography } from "@mui/material";
import { useGetResourceLabel, useResourceContext } from "react-admin";
import { useRandomIllustration } from "~/images/illustrations";
import { CreateButtonWithDefaults } from "./buttons/CreateButtonWithDefaults";
export const Empty = ({
  onClick,
  showButton = true,
  showImage = true,
  title = false,
  subtitle = "",
  buttonLabel = "Get Started",
  resource: custom_resource,
  defaultValues = {},
  children,
}) => {
  const resource = useResourceContext({ resource: custom_resource });
  const getResourceLabel = useGetResourceLabel();

  //const record = useRecordContext();  record={record}
  //at some point need to determine how to identify context here and whether the user has permission for the record
  //currently the record is the parent record if children, which fails the test below when constrained by parent: owner_id
  const Illustration = useRandomIllustration();
  return (
    <Box width="100%" justifyContent="center" textAlign="center" m={1}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "white",
          borderRadius: 3,
          padding: 4,
          height: "100%",
        }}
        rowGap={3}
      >
        {showImage && <Illustration width="10%" height="100px" />}
        <Typography variant="h4">
          {title || `No ${getResourceLabel(resource)} Yet`}
        </Typography>
        {/* <IfCanAccess action="create" resource={resource}> */}
        <Typography variant="body1">{subtitle}</Typography>
        {showButton && (
          <CreateButtonWithDefaults
            defaultValues={defaultValues}
            label={buttonLabel}
            resource={resource}
            onClick={(e) => {
              if (onClick) {
                e.preventDefault();
                onClick(e);
              }
            }}
          />
        )}
        {children}
        {/* </IfCanAccess> */}
      </Stack>
    </Box>
  );
};
