import { Grid, Typography, Box } from "@mui/material";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { map } from "lodash";
import { Article, FileHtml, ImageSquare, Plus } from "@phosphor-icons/react";
import { useMemo, useRef, useState } from "react";
import {
  Button,
  FormDataConsumer,
  Labeled,
  Link,
  RecordContextProvider,
  SimpleFormIterator,
  TextField,
  TextInput,
  useCreate,
  useGetList,
  useGetManyReference,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { MultiImageInput } from "~/components";
import { GridCard } from "~/components/GridCard";
import { BlockInput } from "~/components/inputs/block_editor/BlockInput";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { classifyResource, titleize } from "~/helpers";
import { useTargetType } from "~/hooks";
import { MarkdownLogo } from "@phosphor-icons/react/dist/ssr";

export const ContentInlineEditList = ({ disableAdd, disableRemove }) => {
  const target_type = useTargetType();

  return (
    <Grid container>
      <ReferenceManyInput
        reference="contents"
        target="target_id"
        label={false}
        filter={{ target_type }}
      >
        <SimpleFormIterator
          fullWidth
          disableClear
          disableReordering
          disableAdd={true}
          disableRemove={disableRemove}
          sx={{ "& .RaSimpleFormIterator-line": { paddingY: 2 } }}
        >
          <FormDataConsumer>
            {(props) => <ContentInlineEditForm {...props} />}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ReferenceManyInput>
      {!disableAdd && <AvailableContentTypeButtons />}
    </Grid>
  );
};

const ContentInlineEditForm = ({
  getSource: baseGetSource,
  scopedFormData,
}) => {
  const getSource = useRef(baseGetSource).current;
  const [record] = useState(() => ({ ...scopedFormData }));
  const { content_type_type, content_type_name, content_type_use_title } =
    record;
  const field = useMemo(() => {
    return getContentField({ getSource, type: content_type_type });
  }, [getSource, content_type_type]);

  const label = titleize(content_type_name);
  return (
    <Labeled label={label} fullWidth>
      <RecordContextProvider value={record}>
        <TextField source="content_type_description" />
        <GridCard
          width="100%"
          sx={{ minHeight: "20vh", maxHeight: "70vh", overflow: "auto" }}
        >
          {content_type_use_title && (
            <TextInput
              source={getSource("title")}
              label={false}
              helperText="Optional Title"
            />
          )}
          {field}
        </GridCard>
      </RecordContextProvider>
    </Labeled>
  );
};

export const getContentField = ({ getSource, type }) => {
  switch (type) {
    case "ContentType::PlainText":
      return <TextInput source={getSource("description")} label={false} />;
    case "ContentType::Media":
      return <MultiImageInput source={getSource("media")} />;
    case "ContentType::Markdown":
      return (
        <ItinRichTextArea
          placeholder="Additional Content.."
          source={getSource("description")}
          isRequired={false}
        />
      );
    case "ContentType::MediaAndMarkdown":
      return (
        <>
          <MultiImageInput source={getSource("media")} />
          <ItinRichTextArea
            placeholder="Additional Content.."
            source={getSource("description")}
            isRequired={false}
          />
        </>
      );
    case "ContentType::BlockEditor":
      return (
        <BlockInput
          source={getSource("json_data")}
          html_source={getSource("html")}
        />
      );
    case "ContentType::Url":
      return (
        <TextInput
          source={getSource("url")}
          label={false}
          helperText="Complete Url, ie. https://tourismo.co"
        />
      );
    default:
      return <p>Content {type} Type not found</p>;
  }
};

export const AvailableContentTypeButtons = (props) => {
  const target_type = useTargetType();

  const resource = useResourceContext(props);
  const record = useRecordContext(props);

  const { data: contents, isLoading } = useGetManyReference("contents", {
    target: "target_id",
    id: record.id,
    filter: { target_type },
  });

  const { data: available_content_types, isLoading: isLoadingContentTypes } =
    useGetList("content_types", {
      filter: {
        class_type: classifyResource(resource),
        not: { id: map(contents, "content_type_id") },
      },
    });

  if (isLoading || isLoadingContentTypes) return "loading...";
  return available_content_types?.map((act) => (
    <CreateContentButton act={act} />
  ));
};

const CONTENT_TYPE_ICONS = {
  "ContentType::Markdown": <MarkdownLogo />,
  "ContentType::Media": <ImageSquare />,
  "ContentType::MediaAndMarkdown": <ImageSquare />,
  "ContentType::BlockEditor": <FileHtml />,
  "ContentType::PlainText": <Article />,
  "ContentType::Url": <Link />,
};

const CreateContentButton = ({ act }) => {
  const record = useRecordContext();

  const [create] = useCreate("contents", {
    data: {
      target_type: act.class_type,
      target_id: record.id,
      content_type_id: act.id,
    },
  });

  const handleClick = () => {
    create();
  };
  return (
    <GridCard item xs={4} justifyContent="center">
      <Typography variant="h2" align="center">
        {CONTENT_TYPE_ICONS[act.type]}
      </Typography>
      <Typography variant="h6" align="center">
        {titleize(act.name)}
      </Typography>
      <Typography align="center">
        <em>{act.description}</em>
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          startIcon={<Plus />}
          label={"Add Content"}
          onClick={handleClick}
          variant="contained"
          sx={{ width: "150px" }}
        />
      </Box>
    </GridCard>
  );
};

/*

    exclude the ones that we already have,
   */
